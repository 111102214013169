<template>
  <div>

    <v-row wrap>
      <v-col cols="12" class="pa-0">
        <v-card tile>
          <v-img src="../../assets/bg/bg3_1.jpg" height="650">
            <div style="margin-top:90px;">
              <v-row wrap justify="center">

                    <v-col cols="12" md="5" lg="4" xl="3" class="pt-5">
                        <v-list-item class="justify-center justify-md-start pb-5">
                          <span class="white--text font-weight-bold text-h3 text-sm-h2">보고 듣는</span>
                        </v-list-item>
                        <v-list-item class="justify-center justify-md-start pb-5">
                          <span class="white--text font-weight-bold text-h3 text-sm-h2">나만의</span>
                        </v-list-item>
                        <v-list-item class="justify-center justify-md-start pb-5">
                          <span class="white--text font-weight-bold text-h3 text-sm-h2">온라인</span>
                        </v-list-item>
                        <v-list-item class="justify-center justify-md-start pb-5">
                          <span class="white--text font-weight-bold text-h3 text-sm-h2">드라이브</span>
                        </v-list-item>
                        <v-list-item>
                          <v-row wrap class="justify-center justify-md-start">
                          <h6 class="pt-13 pl-4 text-justify font-weight-thin" style="line-height:1.2em">
                            <v-btn
                              class="primary--text font-weight-bold"
                              color="white"
                              @click="signIn"
                              x-large
                            >시작하기</v-btn>
                          </h6>
                          </v-row>
                        </v-list-item>

                    </v-col>
                    <v-col cols="12" md="5" lg="4" xl="3">
                      <v-list-item class="d-none d-md-flex">
                        <v-img
                          max-width="460"
                          contain
                          src="@/assets/bg/right1.png"></v-img>
                      </v-list-item>
                    </v-col>

              </v-row>
            </div>
          </v-img>
        </v-card>
        <v-container fluid>
          <v-row wrap justify="center">
            <v-col cols="12" md="9" xl="7">
              <v-row justify="center" class="my-15">
                <v-col cols="12" sm="7" md="7" lg="7">
                  <v-list-item class="my-lg-5">
                    <div class="text-h5 text-lg-h4">
                      나 만의 온라인 드라이브를 만들 수 있습니다
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-5 pb-xs-5 font-weight-light text-subtitle-1 text-lg-h6">
                      내 컴퓨터, 유튜브, 무료 웹사이트 등 여기저기 흩어져 있어서 찾기 어려웠던 자료들을 카테고리별로 모아 놓고 언제든지 보고 들을 수 있습니다.
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="5" md="5" lg="5">
                  <v-list-item class="my-lg-5">
                    <v-img src="@/assets/background/library_pad.jpg" ></v-img>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row wrap justify="center">
            <v-col cols="12" md="9" xl="7">
              <v-row justify="center" class="my-15">
                <v-col cols="12" sm="5" md="5" lg="5">
                  <v-list-item class="my-lg-5">
                    <v-img src="@/assets/background/exciting.jpg"></v-img>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="7" md="7" lg="7">
                  <v-list-item class="my-lg-5">
                    <div class="text-h5 text-lg-h4">
                      혁신적인 커리큘럼을 만들 수 있습니다
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-5 pb-xs-5 font-weight-light text-subtitle-1 text-lg-h6">
                      유용한 음원 파일이나 유튜브 영상을 목록화하여 커리큘럼을 만들 수 있습니다. 해외 뉴스 등 무료 음원을 제공하는 사이트를 알고 있다면, Text와 mp3를 다운 받고 라이브러리에 등록하여 반복적으로 학습할 수 있습니다.
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row wrap justify="center" class="grey lighten-4">
            <v-col cols="12" md="9" xl="7">
              <v-row wrap justify="center" class="my-10 my-lg-15">
                <v-col cols="12">
                  <v-list-item>
                    <div class="text-subtitle-1 text-lg-h6">

                      <span class="text-h3" style="margin-left: -20px;">
                        <v-icon x-large>mdi-format-quote-open</v-icon>
                      </span>

                      <p class="mt-3 mb-0">
                        클래스아이는 언어를 가르치는 선생님과 내 아이에게 엄마표 교육을 준비하시는 분들을 위해 제작되었습니다.<br/>
                        최근 4차 산업혁명으로 디지털이 중심이 되는 세상으로 변화하면서, 디지털을 이해하고 활용하는 교육이 필요한 때입니다.
                        선생님이 선별한 콘텐츠 라이브러리는 디지털 콘텐츠에 대한 학생들의 이해력을 발전시키는 시작점이 될 수 있습니다.
                      </p>
                      <span class="text-h3 float-right" style="margin-left: -20px;">
                        <v-icon x-large>mdi-format-quote-close</v-icon>
                      </span>
                    </div>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row wrap justify="center">
            <v-col cols="12" md="9" xl="7">
              <v-row justify="center" class="my-15">
                <v-col cols="12" sm="7" md="7" lg="7">
                  <v-list-item class="my-lg-5">
                    <div class="text-h5 text-lg-h4">
                      디지털 리터러시를 갖추는데 도움이 됩니다
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-5 pb-xs-5 font-weight-light text-subtitle-1 text-lg-h6">
                      리터러시란 글을 읽고 쓸 줄 아는 능력을 말합니다. 그러나 이제는 글을 읽고 쓸 줄 아는 것만으로는 부족합니다. 미래는 디지털 리터러시, 즉 디지털을 읽고 분석하고, 사용할 줄 아는 능력이 중요합니다.<br/>
                      <span class="text-caption pt-5">ref. 디지털리터러시교육협회</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="5" md="5" lg="5">
                  <v-list-item class="my-lg-5">
                    <youtube :video-id="yUrl" ref="youtube" @playing="playing"></youtube>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

  </div>
</template>
<script>
export default {
  data () {
    return {
      yUrl: ''
    }
  },
  mounted () {
    this.yUrl = this.getVideoId('https://www.youtube.com/watch?v=yrALp-VbxkY&feature=emb_logo')
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    }
  },
  methods: {
    signIn () {
      if (this.$firebase.auth().currentUser) {
        this.$router.push('/manage/academy')
      } else {
        this.$router.push('/auth/signin')
      }
    },
    async playVideo () {
      await this.player.playVideo()
    },
    playing () {
      // console.log('we are watching!!!')
    },
    getVideoId (url) {
      if (!url) return ''

      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    }
  }
}
</script>
